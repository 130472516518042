import { render, staticRenderFns } from "./VariableCardHeader.vue?vue&type=template&id=61822d3e&scoped=true&"
import script from "./VariableCardHeader.vue?vue&type=script&lang=js&"
export * from "./VariableCardHeader.vue?vue&type=script&lang=js&"
import style0 from "./VariableCardHeader.vue?vue&type=style&index=0&id=61822d3e&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61822d3e",
  null
  
)

export default component.exports