<template>
  <div></div>
</template>

<script>
  export default {
    props: {
      initElementProperty: {
        type: Object,
      },
    },
  };
</script>
